import {
  customElement,
  PLATFORM,
  bindable,
  autoinject
} from 'aurelia-framework';
import './user-info.css';
import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import { getLogger } from 'aurelia-logging';
import { getSession } from '../../../../config/sessionService';
import { UserInfoQuery } from '../../../../../custom_typings/graphql';

@autoinject()
@customElement('user-info')
export class UserInfo extends GraphQLBaseViewModel<void, UserInfoQuery, void> {
  @bindable() navigateToProfile: Function;
  @bindable() logout: Function;
  @bindable() isMobile: boolean;

  constructor() {
    super(getLogger(UserInfo.name));
    this.usergroup = this.getUserGroup();
  }

  query = gql`
    query UserInfoQuery {
      currentUser {
        user {
          name
          avatarUrl
        }
      }
    }
  `;
  usergroup: string | undefined;
  dropdownIsShown = false;

  dataChanged = (data: UserInfoQuery) => {    
    if (
      data && 
      data.currentUser &&
      !data.currentUser.user
    ) {
      this.logout();
    }
  };

  showDropdown() {
    this.dropdownIsShown = true;
    setTimeout(() => PLATFORM.addEventListener('click', this.bodyClickTrigger));
  }

  toggleDropdown() {
    if (!this.dropdownIsShown) this.showDropdown();
    else this.closeDropdown();
  }

  bodyClickTrigger = (e: MouseEvent) => {
    if (e.target == null) return;
    const targetElement = e.target as Element;
    if (targetElement.closest('user-info')) return;

    this.closeDropdown();
  };

  closeDropdown = () => {
    this.dropdownIsShown = false;
    PLATFORM.removeEventListener('click', this.bodyClickTrigger);
  };

  navigateProfileClicked() {
    this.closeDropdown();
    this.navigateToProfile();
  }

  logoutClicked() {
    this.closeDropdown();
    this.logout();
  }

  getUserGroup() {
    const session = getSession();
    return session.currentUser.isSuperUser ? 'Super user' : undefined;
  }
}
