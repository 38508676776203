import { INotifications } from '../../../../../interfaces';
import { browserIsSupported } from '../../../../../utility';

export const getBrowserSupportNotification = (): INotifications | undefined => {
  return !browserIsSupported()
    ? {
        type: 'UNSUPPORTED_BROWSER',
        timestamp: new Date().toString(),
        level: 'warning',
        acknowledged: false
      }
    : undefined;
};
