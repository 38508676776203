import { customElement, PLATFORM, autoinject } from 'aurelia-framework';
import './notifications.css';
import { Router } from 'aurelia-router';
import { INotifications } from '../../../../interfaces';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { notificationNotify } from '../../../../services/notificationService';
import { getBrowserSupportNotification } from './static-notifications/browser-support';
import { routeNames } from '../../../../config/routes';

@autoinject()
@customElement('notifications')
export class Notifications {
  isEmpty = false;
  notificationsIsShown = false;
  unreadCount = 0;

  notifications: INotifications[] = [];
  subscription: Subscription | undefined;

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator
  ) {
    const notifications = this.getStaticNotifications();
    this.notifications = notifications;
    this.unreadCount = notifications.length;
    this.subscription = this.eventAggregator.subscribe(
      notificationNotify,
      (notification: INotifications) => {
        let canAdd = !this.notifications.some(n => n === notification);    // this notification has not been added before
        
        if (notification.type === "SYSTEM-UPDATED" && this.notifications.some(n => n.type === "SYSTEM-UPDATED")) {
            canAdd = false;        // only one sys update notifiation at any time
        }
        if (canAdd) {
          this.notifications.push(notification);
        }

        //Remove expired notifications
        this.notifications = this.notifications.filter(n => !n.acknowledged);
        this.unreadCount = this.notifications.length;
      }
    );
  }

  detached() {
    if (this.subscription) this.subscription.dispose();
  }

  buildVersion() {
    return document.head.querySelector('meta[name="build-version"]')?.getAttribute('content') || 'Unknown';
  }
  
  clearAll() {
    //Update acknowledged flag and re-publish all notifications
    this.notifications.forEach(notification => {
      notification.acknowledged = true;
      this.eventAggregator.publish(notificationNotify, notification);
    });

    this.closeDropdown();
  }

  toggleDropdown() {
    if (!this.notificationsIsShown) this.showDropdown();
    else this.closeDropdown();
  }

  showDropdown() {
    this.notificationsIsShown = true;
    this.unreadCount = 0;
    setTimeout(() => PLATFORM.addEventListener('click', this.bodyClickTrigger));
  }

  bodyClickTrigger = (e: MouseEvent) => {
    if (e.target == null) return;
    const targetElement = e.target as Element;
    if (targetElement.closest('notifications')) return;

    this.closeDropdown();
  };

  closeDropdown = () => {
    this.notificationsIsShown = false;

    PLATFORM.removeEventListener('click', this.bodyClickTrigger);
  };
  /*
  notifyMe = () => {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification('Hi there!');
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification('Hi there!');
        }
      });
    }

    // Finally, if the user has denied notifications and you
    // want to be respectful there is no need to bother them any more.
  };
*/
  navigateToUnsupportedBrowser = () => {
    this.router.navigateToRoute('unsupportedbrowser');
    this.closeDropdown();
  };

  navigateToReleaseNotes(): void {
    this.dismissSystemUpdateNotifications();
    this.router.navigateToRoute(routeNames.releasenotes);
    this.closeDropdown();
  }

  getStaticNotifications = (): INotifications[] => {
    const maybeNotification = getBrowserSupportNotification();

    return maybeNotification ? [maybeNotification] : [];
  };

  getIconForNotification = (notification: INotifications) => {
    switch (notification.level) {
      case 'warning':
        return 'fa-exclamation-triangle';
      case 'error':
        return 'fa-exclamation-triangle';
      default:
        return 'fa-info-circle';
    }
  };

  /** There is only ever one sys updated notification in the list, but this function assumes there can be many */
  private dismissSystemUpdateNotifications() {
    // create new array of selected notifications. This avoids looping over the notification array that can change in the event aggregator subscription function:
    let sysUpdateNotifications = this.notifications.filter(n => n.type === 'SYSTEM-UPDATED');
    
    sysUpdateNotifications.forEach(notification => {
      notification.acknowledged = true;
      this.eventAggregator.publish(notificationNotify, notification);
    });
  }
}
