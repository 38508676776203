import { customElement } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import './tooltips-displayer.css';
import { TooltipEvent, tooltipEvent } from '../../../../models/tooltip';

interface ITooltipItem {
    id: unknown,
    text: string,
    placement: 'top' | 'left',
    domRect: DOMRect
}
@customElement('tooltips-displayer')
export class TooltipsDisplayer {

    tooltipsToShow: ITooltipItem[] = []

    private subscription: Subscription;
    constructor(subscriber: EventAggregator){
        this.subscription = subscriber.subscribe(tooltipEvent, (event: TooltipEvent) => {
            switch(event.type) {
                case 'hide':
                    this.tooltipsToShow = this.tooltipsToShow.filter(t => t.id !== event.id)
                    break;
                case 'show':
                    this.tooltipsToShow.push({
                        domRect: event.properties.attachedElement,
                        placement: event.properties.placement,
                        text: event.properties.text,
                        id: event.id
                    })
                    break;
            }
        })
    }


    getCss(item: ITooltipItem): unknown | undefined {
        if(item.placement === 'top') {
            return { bottom: `calc(100% - ${item.domRect.top}px + 0.5rem)`, left: item.domRect.left +'px' };
        }
        if(item.placement === 'left') {
            return { top: item.domRect.top + 'px', right: `calc(100% - ${item.domRect.left}px)` };
        }
        return;
    }

    detach(){
        this.subscription && this.subscription.dispose();
    }
}