import {
  Router,
  RouterConfiguration,
  RouteConfig,
  NavModel,
  NavigationInstruction,
  Next,
  Redirect
} from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { rootState } from '../../reducers';
import { BaseViewModel } from '../common/BaseViewModel';
import { autoinject, computedFrom } from 'aurelia-framework';
import { bodyClick } from '../../actions';
import {
  getNewId,
  isNone,
  routeIsActive,
  orderByPredicate,
} from '../../utility';
import { routes, MenuGroupEnum } from '../../interfaces';
import { setupRouterWithRedux, routeNames } from '../../config';
import { selectCurrentRouteName } from '../common';
import { deviceSize } from '../../types';
import { getLogger } from 'aurelia-logging';
import { deviceSizeChanged } from '../../actions/device';
import {
  isMobileWatcher,
  isTabletWatcher,
  isDesktopWatcher,
  isTouchDeviceWatcher
} from '../../utility/deviceHelpers';
import { BuidService, ChannelService, ControllerService, SiteService } from '../../services';
import 'normalize.css';
import '../common/global.css';
import './app.css';
import { IDeviceReducerState } from '../../reducers/ui/deviceReducer';
import { I18N } from 'aurelia-i18n';
import { getSession } from '../../../src/config/sessionService';
import { AuthenticationService } from '$services/authenticationService';

const isMobile = (device: IDeviceReducerState) => {
  return device.screenSize === 'mobile';
};

const getTitle = (
  route: routes,
  state: rootState,
  channelService: ChannelService,
  controllerService: ControllerService,
  siteService: SiteService,  
  i18n: I18N,
  router: Router
): string | undefined => {
  const title = isNone(route.child)
    ? undefined
    : getTitle(
        route.child,
        state,
        channelService,
        controllerService,
        siteService,
        i18n,
        router
      );
  if (title) return title;

  const isMobileDevice = isMobile(state.device);
  if (
    route.route === routeNames.sitedetailsControllerDetails &&
    !isNone(route.params.controllerId) &&
    isMobileDevice
  ) {
    const controllerId = parseInt(route.params.controllerId, 10);
    return controllerId
      ? controllerService
          .getController(controllerId)
          .map(x => x.alias)
          .getEntityOrUndefined()
      : undefined;
  }

  if (
    route.route === routeNames.sitedetailsChannelDetails &&
    !isNone(route.params.siteChannelId) &&
    isMobileDevice
  )
    return channelService
      .getSiteChannelBySiteChannelId(route.params.siteChannelId)
      .map(i => i.alias)
      .getEntityOrUndefined();

  if (
    route.route === routeNames.sitedetailsTanksDetails &&
    !isNone(route.params.siteChannelId) &&
    isMobileDevice
  )
    return channelService
      .getSiteChannelBySiteChannelId(route.params.siteChannelId)
      .map(i => i.alias)
      .getEntityOrUndefined();

  if (route.route === routeNames.sitedetails) { 
    const onError = (status: number, _errorText: string) => { 
      if (status === 404) { 
        router.navigate("/404?type=site")
      }
      
    }
    return siteService
    .getSiteById(route.params.id, onError)
    .map(c => c.alias)
    .getEntityOrUndefined();
  }

  return isMobileDevice ? i18n.tr(route.title) : undefined;
};

const canNavigateBack = ({ router, device }: rootState): boolean =>
  device.screenSize === 'mobile' &&
  !routeIsActive(router.currentRoute, 'dashboard', routeNames.sitelist);

const getPrivacyUrlForLocale = (locale: string | undefined) : string => {
    switch (locale)
    {
      case 'nl-NL':
        return 'https://www.yara.nl/privacy-legal-en-cookies/';

      case 'fr-FR':
        return 'https://www.yara.fr/politique-de-confidentialite/';
      
      case 'dk-DK':
        return 'https://www.yara.dk/privacy-and-legal/';

      case 'es-ES':
        return 'https://www.yara.es/politica-de-privacidad/';

      case 'it-IT':
        return 'https://www.yara.it/privacy-e-legale/';

      case 'nb-NO':
      case 'nn-NO':
        return 'https://www.yara.no/personvern-og-legalitet/';
      
      case 'fi-FI':
        return 'https://www.yara.fi/tietosuoja/';

      case 'pl-PL':
        return 'https://www.yara.pl/prywatnosc/';

      case 'hu-HU':
        return 'https://www.yara.hu/felhasznalasi-feltetetelek/felhasznalasi-feltetelek-adatvedelmi-szabalyzat/';

      case 'de-DE':
        return 'https://www.yara.de/datenschutzerklaerung/';

      case 'sv-SE':
        return 'https://www.yara.se/yaras_sekretesspolicy/';

      case 'en-GB':
        return 'https://www.yara.co.uk/privacy-and-legal/';

      case 'en-US':      
      default:
        return 'https://www.yara.com/privacy/';
    }
  }

const getPrivacyUrlForResponsibleBUID = (responsibleBUID?: string) => {
  switch (responsibleBUID)
  {
    case 'de.nutriox':
      return '/docs/privacy_de_nutriox.pdf';
    default:
      return undefined;
  }
}

interface IAppState {
  route: string | undefined;
  pageTitle?: string;
  menuIsOpenForEntity?: any;
  screenSize: deviceSize;
  canShowReports: boolean;
  canNavigateBack: boolean;
  canShowManagement: boolean;
  canShowRouteManagement: boolean;
  updatedServiceWorkerReady: boolean;
  privacyUrl: string;
  currentRoute: routes;
}

class PostCompleteStep {
  run(_routingContext: NavigationInstruction, next: Next) {
    window.document
      .getElementById('yt3-main-content')
      ?.scrollTo({ top: 0 });

    return next();
  }
}

@autoinject
class BFFAuthorizeStep {
    constructor(private authService: AuthenticationService) {}

    async run(routingContext: NavigationInstruction, next: Next) {
        const isLoggedIn = await this.authService.isAuthenticated()
          const loginRoute = '/bff/login';
          if (routingContext.getAllInstructions().some(route => route.config.auth)) {
              if (!isLoggedIn) {
                  return next.cancel(new Redirect(loginRoute));
              }
          } else if (isLoggedIn && routingContext.getAllInstructions().some(route => route.fragment === loginRoute)) {
              return next.cancel(new Redirect('/'));
          }
          return next();
        }
    }


@autoinject
export class App extends BaseViewModel<IAppState> {
  childRoutesReport = null;

  menuUserProfile = getNewId();
  private unsubscribeIsTouchDevice: Function;
  isTouchDevice: boolean;

  navbarExpanded: boolean;
  navbarStayOpen: boolean;
  showReports: boolean;
  showRouteManagement: boolean;
  showManagement: boolean;

  @computedFrom('navbarStayOpen', 'showManagement', 'showReports', 'showRouteManagement')
  get showingFlyout() {
    return !this.navbarStayOpen && (this.showManagement || this.showReports || this.showRouteManagement);
  }

  @computedFrom()
  get showNavAndTopBar() { 
    return !routeIsActive(this.state.currentRoute, 'sitelistmap-fullscreen');

  }

  constructor(
    private authService: AuthenticationService,
    private channelService: ChannelService,
    private controllerService: ControllerService,
    private siteService: SiteService,
    private buidService: BuidService,
    private router: Router,
    private element: Element,
    private i18n: I18N
  ) {
    super(getLogger('App'));

    this.unsubscribeIsTouchDevice = isTouchDeviceWatcher(
      this.updateIsTouchDevice.bind(this)
    );    
  }

  orderByName(routes: NavModel[]) {
    return orderByPredicate(routes, r => this.i18n.tr(r.title), 'asc');
  }

  updateIsTouchDevice(isTouchDevice: boolean) {
    this.isTouchDevice = isTouchDevice;
  }

  mapState = (state: rootState) => {
    const screenSize = state.device.screenSize;

    const buidFetcher = this.buidService.getAllBuids();

    const responsibleBuid = buidFetcher
      .map(buids => buids.filter(b => b.buidId == getSession().currentUser.buidId))
      .getEntityOrUndefined()?.pop();

    const privacyUrlForResponsibleBUID = getPrivacyUrlForResponsibleBUID(responsibleBuid?.name)

    return {
      privacyUrl: privacyUrlForResponsibleBUID ? privacyUrlForResponsibleBUID : getPrivacyUrlForLocale(this.i18n.getLocale()),    
      route: selectCurrentRouteName(state),
      pageTitle:
        getTitle(
          state.router.currentRoute,
          state,
          this.channelService,
          this.controllerService,
          this.siteService,
          this.i18n,
          this.router
        ) || this.i18n.tr('UI_Header_DefaultTitle'),
      menuIsOpenForEntity: state.application.menuIsOpenForEntity,
      screenSize,
      canShowReports:
        this.features.scheduledReports.read ||
        this.features.reportsDeviceType.read ||
        this.features.reportsProductStock.read ||
        this.features.reportDosing.read ||
        this.features.reportsSiteRefills.read ||
        this.features.reportsNewSites.read ||
        this.features.reportsSitesStatus.read ||
        this.features.userStatisticsReport.read ||
        this.features.reportsAlarm.read ||
        this.features.reportTemperature.read ||
        this.features.reportIdaDeliveries.read ||
        this.features.reportIdaLifecycle.read,
      canShowManagement:
        this.features.contactManager.read ||
        this.features.manageCustomers.read ||
        this.features.manageUsers.read ||
        this.features.controllerManagement.read ||
        this.features.deliveryNoteManager.read ||
        this.features.simManagement.read ||
        this.features.importManagement.read ||
        this.features.exportManagement.read ||
        this.features.productEditor.read ||
        this.features.translations.read,
      currentRoute: state.router.currentRoute,
      canShowRouteManagement: this.features.staalduinenExport.read || this.features.routePlanning.read,
      canNavigateBack: canNavigateBack(state),
      updatedServiceWorkerReady: state.application.updatedServiceWorkerReady
    };
  };

  activate() {
    this.attachMapState(this.mapState);
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.title = 'UI_Titles_Root';
    config.addPipelineStep('postcomplete', PostCompleteStep);

    setupRouterWithRedux(config);

    config.addAuthorizeStep(BFFAuthorizeStep); // Add a route filter so only authenticated uses are authorized to access some routes

    config.options.pushState = true;

    config.map(this.getAccessibleRoutes());

    config.mapUnknownRoutes({
      route: ['404'],
      name: 'not-found',
      moduleId: PLATFORM.moduleName('pages/not-found/not-found', 'not-found'),
      nav: false,
      title: 'UI_Titles_NotFound'
    });

    this.router = router;
  }

  getAccessibleRoutes() {
    const routes: RouteConfig[] = [
      {
        route: [''],
        name: routeNames.dashboard,
        moduleId: PLATFORM.moduleName('pages/dashboard/dashboard-react', 'dashboard-react'),
        nav: true,
        settings: { icon: 'home', level: 0, order: 0 },
        title: 'UI_Titles_Dashboard',
        auth: true
      },
      {
        route: ['go'],
        name: routeNames.go,
        moduleId: PLATFORM.moduleName('pages/go/go', 'go'),
        nav: false,
        settings: { icon: 'home', level: 0 },
        title: 'UI_Titles_Dashboard'
      },
      {
        route: ['404'],
        name: 'not-found',
        moduleId: PLATFORM.moduleName('pages/not-found/not-found', 'not-found'),
        nav: false,
        title: 'UI_Titles_NotFound'
      }
    ]
      
      routes.push({
        route: ['sitedetails/:id'],
        name: routeNames.sitedetails,
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/sitedetails',
          'sitedetails'
        ),
        nav: false,
        settings: { icon: 'list-alt' },
        title: 'UI_Titles_SiteDetails'
      },
      {
        route: ['sitedetails/:id/easyfeed'],
        name: routeNames.sitedetailsEasyFeed,
        moduleId: PLATFORM.moduleName(
        'pages/sitedetails/easyfeed/easyfeed',
        'easyfeed'
        ),
        nav: false,
        settings: { icon: 'list-alt' },
        title: 'UI_Titles_EasyFeed'
      },
      {
        route: ['qr/:serial'],
        name: routeNames.qr,
        moduleId: PLATFORM.moduleName('pages/qr/qr', 'qr'),
        nav: false,
        settings: { icon: 'qrcode' },
        title: 'UI_Titles_QR',
      },
      // Routes that requires you to be logged out.
      // {
      //   route: ['forgot-password', 'reset-password', 'activate-account'],
      //   name: 'logout-required',
      //   moduleId: PLATFORM.moduleName(
      //     'pages/account/modules/logout-required/logout-required',
      //     'logout-required'
      //   ),
      //   nav: false,
      //   settings: { level: 0 },
      //   title: 'UI_Titles_LogoutRequired',
      //   auth: true
      // }
      );

    if(this.features.staalduinenExport.read){
      routes.push(
        {
          route: ['orderplanner'],
          name: routeNames.orderPlanner,
          moduleId: PLATFORM.moduleName('pages/planner/order-planner/order-planner-wrapper', 'orderplanner'),
          nav: true,
          settings: { icon: 'file-o', level: 1, parent: 'routemanagement', class: 'sub-menu', hideOnMobile: true },
          title: 'UI_Titles_OrderPlanner'
        },)
    }
    if(this.features.routePlanning.read){
      routes.push({
        route: ['routeplanner'],
        name: routeNames.routePlanner,
        moduleId: PLATFORM.moduleName(
          'pages/planner/route-planner/route-planner-wrapper',
          'routeplanner'
        ),
        nav: true,
        settings: { icon: 'truck', level: 1, parent: 'routemanagement', class: 'sub-menu', hideOnMobile: true },
        title: 'UI_Titles_RoutePlanner'
      });
    }

    routes.push({
      route: ['sitelistmap', 'sitelistmap-fullscreen'],
      name: 'sitelistmap-fullscreen',
      moduleId: PLATFORM.moduleName(
        'pages/sitelistmap-fullscreen/sitelistmap-fullscreen',
        'sitelistmap-fullscreen'
      ),
      nav: false,
      title: 'UI_Titles_SiteList'
    });

    routes.push({
      route: ['sitelistpage', 'sitelistpage-react', 'sitelist'],
      name: 'sitelistpage-react',
      moduleId: PLATFORM.moduleName(
        'pages/sitelistpage-react/sitelistpage',
        'sitelistpage-react'
      ),
      nav: true,
      settings: { icon: 'sitemap', level: 0, order: 2 },
      title: 'UI_Titles_SiteList'
    });

    routes.push({
      route: ['controllerbackupviewer/:id'],
      name: 'controllerbackupviewer',
      moduleId: PLATFORM.moduleName(
        'pages/controllerbackupviewer/controllerbackupviewer',
        'controllerbackupviewer'
        ),
        nav: false,    
    })

    if (
      this.features.scriptConfigs.read
    ) {
      routes.push({
        route: ['scriptconfigs'],
        name: 'scriptconfigs',
        moduleId: PLATFORM.moduleName(
          'pages/scriptconfigs/scriptconfigs-page',
          'scriptconfigs-page'
          ),
          nav: true,
          settings: { icon: 'file-o', level: 1, parent: 'management', class: 'sub-menu', hideOnMobile: true },
          title: 'UI_Titles_Scriptconfigs'   
      })

      routes.push({
        route: ['scriptconfighistory/:id'],
        name: 'scriptconfig-history',
        moduleId: PLATFORM.moduleName(
          'pages/scriptconfig-history/scriptconfig-history-page',
          'scriptconfig-history-page'
          ),
          nav: false,    
      })
    }

    if (
      this.features.manageCustomers.read ||
      this.features.manageCustomers.write
    )
      routes.push({
        route: ['customermanager'],
        name: 'customermanager',
        moduleId: PLATFORM.moduleName(
          'pages/customermanager/customermanager',
          'customermanager'
        ),
        nav: true,
        settings: {
          icon: 'briefcase',
          level: 1,
          parent: 'management',
          class: 'sub-menu',
          order: 4
        },
        title: 'UI_Titles_Customermanager'
      });

      if(this.features.deliveryNoteManager.read){
        routes.push({
          route: ['deliverynotemanager'],
          name: 'deliverynotemanager',
          moduleId: PLATFORM.moduleName(
            'pages/deliverynotemanager/delivery-note-manager',
            'delivery-note-manager'
          ),
          nav: true,
          settings: { icon: 'file-o', level: 1, parent: 'management', class: 'sub-menu', hideOnMobile: true },
          title: 'UI_Titles_DeliveryNoteManager'
        });
      }

    if (this.features.importManagement.read)
      routes.push({
        route: ['import'],
        name: routeNames.importjobs,
        moduleId: PLATFORM.moduleName('pages/import/import', 'import'),
        nav: true,
        settings: {
          icon: 'sign-in',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
          hideOnMobile: true
        },
        title: 'UI_Titles_Dataimport'
      });

    if (this.features.exportManagement.read)
      routes.push({
        route: ['export'],
        name: 'export',
        moduleId: PLATFORM.moduleName('pages/export/export', 'export'),
        nav: true,
        settings: {
          icon: 'sign-out',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
          hideOnMobile: true
        },
        title: 'UI_Titles_DataExport'
      });

    if (this.features.manageUsers.write || this.features.manageUsers.read)
      routes.push({
        route: ['usermanager'],
        name: 'usermanager',
        moduleId: PLATFORM.moduleName(
          'pages/usermanager/usermanager',
          'usermanager'
        ),
        nav: true,
        settings: {
          icon: 'users',
          level: 1,
          parent: 'management',
          class: 'sub-menu',
          order: 5
        },
        title: 'UI_Titles_Usermanager'
      });

    if (this.features.translations.write)
      routes.push({
        route: ['translations'],
        name: 'language',
        moduleId: PLATFORM.moduleName(
          'pages/translation/translation',
          'translation'
        ),
        nav: true,
        settings: {
          icon: 'globe',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
          hideOnMobile: true
        },
        title: 'UI_Titles_Translations'
      });

    if (this.features.productEditor.write)
      routes.push({
        route: ['products'],
        name: 'product-editor',
        moduleId: PLATFORM.moduleName('pages/products/products', 'products'),
        nav: true,
        settings: {
          icon: 'flask',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
          hideOnMobile: true
        },
        title: 'UI_Titles_Products'
      });

    if (this.features.controllerManagement.read)
      routes.push({
        route: ['controllermanager'],
        name: 'controllermanager',
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/controllermanager',
          'controllermanager'
        ),
        nav: true,
        settings: {
          icon: 'microchip',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
        },
        title: 'UI_Titles_ControllerManager'
      });

      if (this.features.userStatisticsReport.read)
        routes.push({
          route: ['reports/user-statistics/:scheduledReportId?'],
          href: 'reports/user-statistics/',
          name: 'reports/user-statistics',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/user-statistics-report/user-statistics-report.react',
            'user-statistics'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'UI_Reports_UserStatistics',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'UI_Reports_UserStatistics'
        });

    if (this.features.scheduledReports.read)
      routes.push({
        route: ['scheduled-reports/scheduled-reports'],
        name: 'scheduled-reports/scheduled-reports',
        moduleId: PLATFORM.moduleName(
          'pages/scheduled-reports/scheduled-reports',
          'scheduled-reports'
        ),
        nav: true,
        settings: {
          icon: 'calendar',
          level: 1,
          parent: 'reports',
          class: 'sub-menu',
          hideOnMobile: true
        },
        title: 'UI_Titles_ReportSchedules'
      });
    
      if (this.features.reportsDeviceType.read)
      routes.push({
        route: ['reports/device-types/:scheduledReportId?'],
        href: 'reports/device-types',
        name: 'reports/device-type',
        moduleId: PLATFORM.moduleName(
          'pages/reports-react/device-type-report/device-type-report.react',
          'device-types'
        ),
        nav: true,
        settings: {
          icon: 'calendar',
          level: 1,
          parent: 'reports',
          class: 'sub-menu',
          hideOnMobile: true
        },
        title: 'UI_Reports_DeviceType'
      });

      if (this.features.reportsSiteRefills.read)
        routes.push({
          route: ['reports/site-refills'],
          name: 'reports/site-refills',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/site-refills-report/site-refills-report.react',
            'site-refills'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'UI_Reports_SiteRefills',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'UI_Reports_SiteRefills'
        });

      if (this.features.reportsNewSites.read)
      routes.push({
        route: ['reports/new-sites/:scheduledReportId?'],
        href: 'reports/new-sites',
        name: 'reports/new-sites',        
        moduleId: PLATFORM.moduleName(
          'pages/reports/new-sites-report/new-sites-report-page',
          'new-sites'
        ),
        nav: true,
        settings: {
          icon: 'pencil-square',
          menuTitle: 'UI_Reports_NewSites',
          level: 1,
          parent: 'reports',
          class: 'sub-menu',
          hideOnMobile: true
        },
        title: 'UI_Reports_NewSites'
      });

      if (this.features.reportDailyDose.read) {
        routes.push({
            route: ['reports/daily-dose/:scheduledReportId?'],
            href: 'reports/daily-dose',
            name: 'reports/daily-dose',        
          moduleId: PLATFORM.moduleName(
            'pages/reports/daily-dose-report/daily-dose-report-page',
            'dailydose'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'UI_Reports_DailyDose',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'UI_Reports_DailyDose'
        });
      }      

    if (this.features.reportDosing.read)
      routes.push({
        route: ['reports/dosing/:scheduledReportId?'],
        href: 'reports/dosing',
        name: 'reports/dosing',
        moduleId: PLATFORM.moduleName(
          'pages/reports-react/dosing-report/dosing-report.react',
          'dosing'
        ),
          nav: true,
          settings: {
          icon: 'pencil-square',
            menuTitle: 'UI_Reports_Dosing',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
        },
        title: 'UI_Reports_Dosing'
      });     

      if (this.features.reportTemperature.read) {
        routes.push({
          route: ['reports/temperature/:scheduledReportId?'],
          href: 'reports/temperature',
          name: 'reports/temperature',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/temperature-report/temperature-report.react',
            'temperature'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'ui_feature_reports_temperature',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'ui_feature_reports_temperature'
        });
    }


    if (this.features.reportsSitesStatus.read) {
      routes.push({
        route: ['reports/stock-vs-dose/:scheduledReportId?'],
        href: 'reports/stock-vs-dose/',
        name: 'reports-stock-vs-dose',
        moduleId: PLATFORM.moduleName(
          'pages/reports/stock-vs-dose-report/stock-vs-dose-report',
          'stock-vs-dose-report'
        ),
        nav: true,
        settings: {
          icon: 'pencil-square',
          menuTitle: 'UI_Reports_SitesStatus',
          level: 1,
          parent: 'reports',
          class: 'sub-menu',
          hideOnMobile: true
        },
        title: 'UI_Reports_SitesStatus'
      });
    }

    if (this.features.reportsProductStock.read)
      routes.push({
        route: ['reports/product-stock/:scheduledReportId?'],
        href: 'reports/product-stock/',
        name: 'reports/product-stock',
        moduleId: PLATFORM.moduleName(
          'pages/reports-react/product-stock-report/product-stock-report.react',
          'product-stock'
        ),
        nav: true,
        settings: {
          icon: 'pencil-square',
          menuTitle: 'UI_Reports_ProductStock',
          level: 1,
          parent: 'reports',
          class: 'sub-menu',
          hideOnMobile: true
        },
        title: 'UI_Reports_ProductStock'
      });

    if (this.features.reportNitratesMonthly.read)
      routes.push({
        route: ['reports/nitrates-monthly/:scheduledReportId?'],
        href: 'reports/nitrates-monthly/',
        name: 'reports/nitrates-monthly',
        moduleId: PLATFORM.moduleName(
          'pages/reports-react/nitrates-report/nitrates-report-react',
          'nitrates-report'
        ),
        nav: true,
        settings: {
          icon: 'pencil-square',
          menuTitle: 'UI_Reports_NitratesReport',
          level: 1,
          parent: 'reports',
          class: 'sub-menu',
          hideOnMobile: true
        },
        title: 'UI_Reports_NitratesReport'
      });

    if (this.features.reportsAlarm.read)
        routes.push({
          route: ['reports/alarm/:scheduledReportId?'],
          href: 'reports/alarm/',
          name: 'reports/alarm',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/alarm-report/alarm-report.react',
            'alarm'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'UI_Reports_Alarm',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'UI_Reports_Alarm'
        });
      
      if (this.features.reportIdaDeliveries.read) {
        routes.push({
          route: ['reports/idadeliveries/:scheduledReportId?'],
          href: 'reports/idadeliveries',
          name: 'reports/idadeliveries',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/idax-deliveries-report/idax-deliveries-report.react',
            'ida-deliveries'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'UI_Reports_Ida_Deliveries',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'UI_Reports_Ida_Deliveries'
        });
      }
    
      if (this.features.reportIdaLifecycle.read) {
        routes.push({
          route: ['reports/idalifecycle/:scheduledReportId?'],
          href: 'reports/idalifecycle',
          name: 'reports/idalifecycle',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/idax-lifecycle-report/idax-lifecycle-report.react',
            'ida-lifecycle'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'ui_reports_ida_lifecycle_title',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'ui_reports_ida_lifecycle_title'
        });
      }

      if (this.features.reportDryRuns.read) {
        routes.push({
          route: ['reports/dryruns/:scheduledReportId?'],
          href: 'reports/dryruns',
          name: 'reports/dryruns',
          moduleId: PLATFORM.moduleName(
            'pages/reports-react/dry-run-report/dry-run-report.react',
            'dry-runs'
          ),
          nav: true,
          settings: {
            icon: 'pencil-square',
            menuTitle: 'UI_Reports_DryRuns',
            level: 1,
            parent: 'reports',
            class: 'sub-menu',
            hideOnMobile: true
          },
          title: 'UI_Reports_DryRuns'
        });
    } 

    if (this.features.contactManager.read)
      routes.push({
        route: ['contactmanager/:action?/:contactId?'],
        name: routeNames.contactmanager,
        href: 'contactmanager',
        moduleId: PLATFORM.moduleName(
          'pages/contactmanager/contactmanager',
          'contactmanager'
        ),
        nav: true,
        settings: {
          icon: 'id-card-o',
          menuTitle: 'UI_Feature_ContactManager',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
          order: 6
        },
        title: 'UI_Feature_ContactManager'
      });

    if (this.features.simManagement.read)
      routes.push({
        route: ['sim-management'],
        name: routeNames.simManagement,
        href: 'sim-management',
        moduleId: PLATFORM.moduleName(
          'pages/sim-management/sim-management',
          'sim-management'
        ),
        nav: true,
        settings: {
          icon: 'mobile',
          menuTitle: 'UI_Feature_Sim_Management',
          level: 1,
          class: 'sub-menu sub-menu',
          parent: 'management',
          hideOnMobile: true
        },
        title: 'UI_Feature_Sim_Management'
      });

    if (this.features.haulierManager.read) {
      routes.push({
        route: ['haulier-manager'],
        name: 'hauliermanager',
        moduleId: PLATFORM.moduleName(
          'pages/hauliermanager/hauliermanager',
          'hauliermanager'
        ),
        nav: true,
        settings: {
          icon: 'truck',
          menuTitle: 'ui_feature_hauliermanager',
          level: 1,
          class: 'sub-menu sub-menu',
          parent: 'management',
          hideOnMobile: true
        },
        title: 'ui_feature_hauliermanager'
      });
    }

    if (getSession().currentUser.isSuperUser) {
      routes.push({
        route: ['system-message-manager'],
        name: routeNames.systemmessagesmanager,
        moduleId: PLATFORM.moduleName(
          'pages/system-message-manager/system-message-manager-page',
          'system-messages-manager'
        ),
        nav: true,
        settings: {
          icon: 'comments-o',
          menuTitle: 'UI_Feature_SystemMessagesManager',
          level: 1,
          class: 'sub-menu',
          parent: 'management',
          order: 7
        },
        title: 'UI_Feature_SystemMessagesManager'
      });
    }

    routes.push({
      route: ['user'],
      name: 'userprofile',
      moduleId: PLATFORM.moduleName(
        'pages/user-profile/user-profile',
        'user-profile'
      ),
      nav: false,
      settings: { icon: 'user', level: 0 },
      title: 'UI_Titles_UserProfile'
    });

    routes.push({
      route: ['releasenotes'],
      name: routeNames.releasenotes,
      moduleId: PLATFORM.moduleName(
        'pages/releasenotes/releasenotespage',
        'releasenotespage'
      ),
      nav: false,
      settings: { icon: 'list-alt', level: 0},
      title: 'UI_Titles_ReleaseNotes'
    });  

    if (this.features.liveMap.read) {
      routes.push({
        route: ['livemap'],
        name: 'livemap',
        moduleId: PLATFORM.moduleName('pages/livemap/livemap', 'livemap'),
        nav: false,
        href: 'livemap',
        settings: { icon: 'map', level: 0 },
        title: 'UI_Titles_LiveMap'
      });
    }

    routes.push({
      route: ['unsupportedbrowser'],
      name: 'unsupportedbrowser',
      moduleId: PLATFORM.moduleName(
        'pages/unsupportedbrowser/unsupportedbrowser',
        'unsupportedbrowser'
      ),
      nav: false,
      title: 'Unsupported browser'
    });

    if (ENV !== 'production') {

      routes.push({
        route: ['design/:component?'],
        name: 'design',
        moduleId: PLATFORM.moduleName('pages/design/design', 'design'),
        nav: true,
        href: 'design',
        settings: { icon: 'paint-brush', level: 0, order: 4 },
        title: 'UT_Titles_DesignSystem'
      });
    
      routes.push({
        route: ['example'],
        name: 'example',
        moduleId: PLATFORM.moduleName('pages/example/example-page', 'example-page'),
        nav: false,
        href: 'example',
        settings: { icon: 'files-o', level: 0, order: 4 },
        title: 'example'
      });

      routes.push({
        route: ['example-list'],
        name: 'example-list',
        moduleId: PLATFORM.moduleName('pages/example-list/example-list-page', 'example-list-page'),
        nav: false,
        href: 'example-list',
        settings: { icon: 'files-o', level: 0, order: 4 },
        title: 'example-list'
      });
    }

    return routes;
  }

  notifyReactRouter(href: string) {
    if (href === '/controllermanager') {
      const event = new Event("notifyReactRouter_goToControllerManager");
      document.dispatchEvent(event);
    }
    // can add more events for other react routes here later if it is necessary
  }

  onClick = () => {
    if(!isNone(this.state.menuIsOpenForEntity))
      this.dispatch(bodyClick());
  };

  private menuGroupNameToEnum(menuGroupName: string) {
    switch(menuGroupName){
      case 'management':
        return MenuGroupEnum.Management;
      case 'reports':
        return MenuGroupEnum.Reports;
      case 'routemanagement':
        return MenuGroupEnum.RouteManagement;
      default:
        throw Error('Could not parse menugroupname: ' + menuGroupName)
    }
  }

  toggleMenuGroup(groupName: string) {
    this.toggleMenuGroupInternal(this.menuGroupNameToEnum(groupName));
  }

  timeoutMs = 300;
  enterTimerId: number | undefined;
  leaveTimerId: number | undefined;

  mouseEnterMenuGroup(groupName: string) {

    if (this.navbarStayOpen) return;

    if (!this.showingFlyout) {
      // if none shown, then show immediately
      this.showMenuGroupInternal(this.menuGroupNameToEnum(groupName));
      return;
    }

    // Opening delay. It makes pointer movements slightly easier for users.
    clearTimeout(this.enterTimerId);
    this.enterTimerId = window.setTimeout(
      () => this.showMenuGroupInternal(this.menuGroupNameToEnum(groupName)),
      this.timeoutMs
    );
  }

  /* Close a single menu group */
  mouseLeaveMenuGroup(groupName: string) {

    if (this.navbarStayOpen) return;
    
    clearTimeout(this.leaveTimerId);
    this.leaveTimerId = window.setTimeout(
      () => {
        switch (groupName) {
          case 'reports':
            this.showReports = false;
            break;
          case 'management':
            this.showManagement = false;
            break;
          case 'routemanagement':
            this.showRouteManagement = false;
            break;
        }
      },
      this.timeoutMs
    );
  }

  clickOutsideFlyout(e: Event) {
    this.hideAllMenuGroups();
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  attached() {
    this.element.addEventListener('click', this.onClick);
    isMobileWatcher(isMobile =>
      isMobile && this.state.screenSize !== 'mobile'
        ? this.dispatch(deviceSizeChanged('mobile'))
        : undefined
    );
    isTabletWatcher(isTablet =>
      isTablet && this.state.screenSize !== 'tablet'
        ? this.dispatch(deviceSizeChanged('tablet'))
        : undefined
    );
    isDesktopWatcher(isDesktop =>
      isDesktop && this.state.screenSize !== 'desktop'
        ? this.dispatch(deviceSizeChanged('desktop'))
        : undefined
    );
  }

  detached() {
    super.detached();
    this.element.removeEventListener('click', this.onClick);
    this.unsubscribeIsTouchDevice();
  }

  close() {
    this.navbarExpanded = false;
    this.hideAllMenuGroups();
  }

  goBack() {
    window.history.go(-1);
  }

  toggle(): void {
    this.navbarExpanded = !this.navbarExpanded;
  }

  toggleDesktop(): void {
    this.navbarExpanded = false;
    this.navbarStayOpen = !this.navbarStayOpen;
    this.hideAllMenuGroups();
  }

  logout() {
    this.authService.logout();
  }

  navigateProfile() {
    this.router.navigate('user');
    if (this.state.screenSize == 'mobile') this.close();
  }

  private toggleMenuGroupInternal(group: MenuGroupEnum) {
    if (this.navbarStayOpen) {
      this.showReports =
        group === MenuGroupEnum.Reports ? !this.showReports : this.showReports;
      this.showManagement =
        group === MenuGroupEnum.Management
          ? !this.showManagement
          : this.showManagement;
      this.showRouteManagement = group === MenuGroupEnum.RouteManagement ? !this.showRouteManagement : this.showRouteManagement;
    } else {
      this.showReports =
        group === MenuGroupEnum.Reports ? !this.showReports : false;
      this.showManagement =
        group === MenuGroupEnum.Management ? !this.showManagement : false;
      this.showRouteManagement = group === MenuGroupEnum.RouteManagement ? !this.showRouteManagement : false;
    }
  }

  private showMenuGroupInternal(menuGroup: MenuGroupEnum) {
    this.showReports = menuGroup === MenuGroupEnum.Reports;
    this.showManagement = menuGroup === MenuGroupEnum.Management;
    this.showRouteManagement = menuGroup === MenuGroupEnum.RouteManagement;
  }

  private hideAllMenuGroups() {
    this.showReports = false;
    this.showManagement = false;
    this.showRouteManagement = false;
  }
}
